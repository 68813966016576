@import "../../../index.css";

.container {
	width: 100%;
	height: 56vw;
	max-height: 700px;
	min-height: 600px;
	display: flex;
	background-image: url("../../../assets/contact.png");
	background-size: cover;
	background-position: center;
}

.overlay {
	display: flex;
	flex-direction: column;
	flex: 1;
	background: linear-gradient(
		105.69deg,
		#10312a 24.33%,
		rgba(16, 49, 42, 0.69) 110.62%,
		rgba(16, 49, 42, 0) 110.63%
	);
}

.infoContainer {
	flex: 1;
	display: flex;
	justify-content: space-between;
}

.infoItemContainer {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}

.infoIcon {
	width: max(2vw, 25px);
	height: auto;
	padding: 0 5px;
}

.infoText {
	color: rgba(233, 240, 235, 1);
	font-size: clamp(12px, 1.2vw, 20px);
	padding-bottom: 3px;
}

.formContainer {
	flex: 5;
	display: flex;
}

.formText {
	flex: 1.2;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 0 10%;
	font-family: Garet;
}

.title {
	font-size: clamp(16px, 1.6vw, 25px);
	color: rgba(233, 240, 235, 1);
	font-weight: 600;
	padding-bottom: 25px;
}

.text {
	font-size: clamp(12px, 1.4vw, 20px);
	color: rgba(233, 240, 235, 1);
}

.form {
	flex: 1.6;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 0 8%;
}

.input {
	background: transparent;
	border: 1px solid rgba(233, 240, 235, 1);
	font-size: clamp(14px, 1.4vw, 20px);
	color: rgba(233, 240, 235, 1);
	font-family: Garet;
	padding: min(1.2vw, 15px);
	margin-bottom: 20px;
	outline: none;
}

.sendButton {
	font-weight: 500;
	font-size: clamp(14px, 1.2vw, 25px);
	padding: 12px 5%;
	background-color: rgba(233, 240, 235, 1);
	border: 1px solid rgba(233, 240, 235, 1);
	width: fit-content;
	cursor: pointer;
}

@media screen and (max-width: 900px) {
	.container {
		min-height: 800px;
	}

	.overlay {
		align-items: center;
	}

	.infoContainer {
		flex-direction: column;
		flex: 1.6;
		justify-content: center;
	}

	.formContainer {
		flex-direction: column;
		width: 70%;
		min-width: 400px;
	}

	.infoItemContainer {
		flex: none;
		padding: 5px 0;
	}

	.infoText {
		font-size: clamp(14px, 2vw, 20px);
	}

	.formText {
		flex: 1;
	}

	.title {
		padding-bottom: 15px;
	}

	.form {
		flex: 1.8;
	}
}

@media screen and (max-width: 500px) {
	.container {
		min-height: 720px;
	}
	.infoItemContainer {
		justify-content: flex-start;
	}
	.infoContainer {
		width: 100%;
	}
	.formContainer {
		width: 100%;
		min-width: 200px;
	}
	.formText {
		padding: 0;
		flex: 0.8;
	}
	.form {
		padding: 0;
		flex: 2;
	}
	.overlay {
		padding: 0 8%;
	}
	.input {
		font-size: clamp(14px, 1.4vw, 20px);
		padding: min(2.5vw, 16px);
	}
	.infoText {
		font-size: clamp(13px, 3.5vw, 20px);
	}
	.infoIcon {
		width: max(4vw, 30px);
		padding: 0 10px 0 0;
	}
	.sendButton {
		padding: 12px 8%;
		font-size: clamp(14px, 2.8vw, 25px);
	}
}
