@import "../../../index.css";

.container {
	font-size: clamp(12px, 1.3vw, 20px);
	font-family: "Garet";
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.firstSection {
	display: flex;
	padding: 4em 0;
	max-width: 1400px;
}

.imageContainer {
	flex: 0.5;
	display: flex;
	justify-content: center;
	align-items: center;
}

.firstImage {
	width: min(34vw, 500px);
	height: min(34vw, 500px);
	min-height: 320px;
	z-index: 2;
}

.textContainer {
	flex: 0.5;
	padding: 4em 4em 4em 0;
	max-height: 560px;
}

.title1 {
	color: rgba(16, 49, 42, 1);
	font-size: clamp(20px, 2.5vw, 36px);
	font-weight: 800;
	font-family: BlackMango;
}

.title2 {
	color: rgba(205, 154, 73, 1);
	font-size: clamp(20px, 2.5vw, 36px);
	font-weight: 600;
	font-family: BlackMango;
	border-bottom: 2px solid rgba(233, 240, 235, 1);
	width: fit-content;
	padding-right: 1em;
}

.description {
	font-size: clamp(12px, 1.3vw, 20px);
	padding-top: 2em;
}

/*SECOND SECTION*/

.gradientBackground {
	background: linear-gradient(90deg,
			rgba(107, 121, 106, 1) 75%,
			rgba(255, 255, 255, 1) 75%);
	display: flex;
	width: 100%;
	justify-content: center;
}

.secondSection {
	display: flex;
	padding: 4em 5em 4em 0;
	max-width: 1400px;
}

.secondTextContainer {
	color: rgba(233, 240, 235, 1);
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: fit-content;
	padding-left: 6em;
	padding-right: 3em;
}

.secondTitle {
	font-size: clamp(20px, 2.5vw, 36px);
	font-weight: 800;
	font-family: BlackMango;
	border-bottom: 2px solid rgba(233, 240, 235, 0.2);
	width: fit-content;
	padding-right: 1em;
}

.options {
	columns: auto 2;
	width: 100%;
}

.optionContainer {
	display: flex;
	align-items: center;
	padding-bottom: 0.5em;
}

.optionText {
	padding-left: 0.5em;
}

.optionImage {
	width: min(1.4vw, 20px);
}

.price {
	border: 1px solid rgba(245, 241, 233, 1);
	font-family: GaretBold;
	width: fit-content;
	padding: 7px;
}

/*THIRD SECTION*/

.frame {
	width: min(34vw, 500px);
	height: min(34vw, 500px);
	min-height: 320px;
	border: 1px solid rgba(107, 121, 106, 1);
}

.thirdImage {
	width: min(34vw, 500px);
	height: min(34vw, 500px);
	min-height: 320px;
	position: absolute;
	margin-left: -4em;
	margin-top: -4em;
}

.thirdTitle {
	font-size: clamp(20px, 2.5vw, 36px);
	font-weight: 800;
	font-family: BlackMango;
	border-bottom: 2px solid rgba(233, 240, 235, 1);
	color: rgba(16, 49, 42, 1);
	width: fit-content;
	padding-right: 2em;
}

.priceJeep {
	background-color: rgba(107, 121, 106, 1);
	font-family: GaretBold;
	width: fit-content;
	padding: 7px;
	color: rgba(245, 241, 233, 1);
}

@media screen and (min-width: 2000px) {
	.gradientBackground {
		background: linear-gradient(90deg,
				rgba(107, 121, 106, 1) 70%,
				rgba(255, 255, 255, 1) 70%);
	}
}

@media screen and (max-width: 900px) {
	.container {
		font-size: clamp(10px, 1.8vw, 20px);
	}

	.firstImage {
		width: min(43vw, 350px);
		height: min(58vw, 550px);
	}

	.textContainer {
		padding: 1em 2em 1em 0;
	}

	.title1 {
		font-size: clamp(16px, 3.7vw, 36px);
	}

	.title2 {
		font-size: clamp(16px, 3.7vw, 36px);
	}

	.description {
		font-size: clamp(10px, 1.8vw, 20px);
	}

	.secondSection {
		padding-right: 2em;
	}

	.secondTextContainer {
		padding-left: 3em;
	}

	.secondTitle {
		font-size: clamp(16px, 3.5vw, 36px);
	}

	.options {
		columns: auto 1;
	}

	.optionText {
		font-size: clamp(10px, 1.8vw, 20px);
	}

	.price {
		font-size: clamp(10px, 1.8vw, 20px);
	}

	.optionImage {
		width: clamp(10px, 1.8vw, 20px);
	}

	.frame {
		width: min(43vw, 350px);
		height: min(58vw, 550px);
	}

	.thirdImage {
		width: min(43vw, 350px);
		height: min(58vw, 550px);
		margin-left: -2em;
		margin-top: -2em;
	}
}

@media screen and (max-width: 500px) {
	.firstSection {
		flex-direction: column;
		text-align: center;
		padding-bottom: 0;
		margin-bottom: -5em;
	}

	.firstImage {
		width: 75vw;
		min-width: 250px;
		height: auto;
		min-height: auto;
	}

	.textContainer {
		padding: 1em 3em 0 3em;
	}

	.title2 {
		text-align: center;
		padding: 0;
		width: 100%;
	}

	.description {
		font-size: clamp(12px, 2vw, 20px);
	}

	.gradientBackground {
		background: linear-gradient(0deg,
				rgba(107, 121, 106, 1) 75%,
				rgba(255, 255, 255, 1) 75%);
	}

	.secondSection {
		flex-direction: column-reverse;
		padding: 5em 2.5em 4em 2.5em;
	}

	.secondImageContainer {
		display: flex;
		justify-content: center;
	}

	.secondTextContainer {
		padding: 0 2em 0 2em;
	}

	.secondTitle {
		text-align: center;
		width: 100%;
		font-size: clamp(12px, 4.5vw, 22px);
		padding-top: 1em;
	}

	.thirdTitle {
		text-align: center;
		width: 100%;
		font-size: clamp(12px, 4.5vw, 22px);
		padding-top: 3em;
	}

	.thirdImage {
		width: 75vw;
		min-width: 250px;
		height: auto;
		min-height: auto;
		margin: 0;
	}

	.frame {
		width: 75vw;
		min-width: 250px;
		height: 110vw;
		margin-right: -4em;
		margin-bottom: -4em;
	}

	.optionText {
		font-size: clamp(12px, 2vw, 20px);
	}

	.optionImage {
		width: clamp(10px, 4vw, 20px);
	}
}