@import "../../../index.css";

.headerImage {
	display: flex;
	width: 100%;
	height: 60vw;
	max-height: 800px;
	background-image: url("../../../assets/homeHeader.png");
	background-size: cover;
	background-position: center;
	z-index: 1;
	position: relative;
}

.activitiesHeader {
	background-image: url("../../../assets/activitiesHeader.png");
}

.headerOverlay {
	background-color: rgba(8, 26, 22, 0.85);
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.navigation {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	padding: 10px 0;
	flex: 0.55;
}

.dropdownContainer {
	flex: 0.22;
	display: flex;
	justify-content: center;
	align-items: center;
}

.logo {
	width: auto;
	height: min(5vw, 76px);
	min-height: 60px;
}

.buttons {
	color: rgba(233, 240, 235, 1);
	font-weight: 300;
	font-size: min(1.3vw, 18px);
	cursor: pointer;
	font-family: "Garet";
}
.buttons:hover {
	text-decoration: underline;
}

.welcomeContainer {
	display: flex;
	flex: 1;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding-bottom: 5%;
}

.welcomeTitle {
	font-size: min(2.7vw, 48px);
	font-family: "BlackMango";
	font-weight: 500;
	color: rgba(233, 240, 235, 1);
}

.welcomeSubtitle {
	font-size: min(2vw, 32px);
	font-family: "Garet";
	font-weight: 200;
	color: rgba(233, 240, 235, 1);
	margin: -0.5em 0 2em 0;
}

.activitiesSubtitle {
	font-size: min(1.5vw, 24px);
	width: 60%;
	font-family: "Garet";
	font-weight: 200;
	color: rgba(233, 240, 235, 1);
	margin: 0.5em 0 2em 0;
	text-align: center;
}

.contactButton {
	width: min(15vw, 220px);
	height: clamp(35px, 3vw, 50px);
	border-width: 1px;
	border-color: rgba(233, 240, 235, 0.8);
	border-style: solid;
	color: rgba(233, 240, 235, 1);
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-family: "Garet";
	font-size: min(1vw, 16px);
	font-weight: 400;
	cursor: pointer;
}

.contactButton:hover {
	color: rgba(8, 26, 22, 0.85);
	border-color: rgba(233, 240, 235, 0);
	background-color: rgba(233, 240, 235, 1);
}

* {
	margin: 0;
	padding: 0;
}

/*TABLET*/
@media screen and (max-width: 900px) {
	.headerImage {
		min-height: 700px;
	}

	.welcomeTitle {
		font-size: clamp(28px, 4.5vw, 36px);
		width: 42%;
		min-width: 200px;
		text-align: center;
		line-height: 1em;
	}

	.activitiesTitle {
		font-size: clamp(28px, 4.5vw, 36px);
		min-width: 200px;
		width: 80%;
		text-align: center;
		line-height: 1em;
		font-family: "BlackMango";
		font-weight: 500;
		color: rgba(233, 240, 235, 1);
	}

	.welcomeSubtitle {
		font-size: clamp(16px, 2.5vw, 20px);
		margin-top: 10px;
	}

	.activitiesSubtitle {
		font-size: clamp(16px, 2.5vw, 20px);
		margin-top: 30px;
	}

	.contactButton {
		font-size: clamp(10px, 1.8vw, 16px);
		width: clamp(130px, 23vw, 200px);
		height: clamp(35px, 6vw, 50px);
	}
}

/*MOBILE*/
@media screen and (max-width: 500px) {
	.welcomeTitle {
		font-size: clamp(28px, 8vw, 36px);
		width: 70%;
		min-width: 215px;
	}

	.activitiesTitle {
		font-size: clamp(28px, 8vw, 36px);
	}

	.welcomeSubtitle {
		font-size: clamp(16px, 4.5vw, 20px);
	}

	.contactButton {
		font-size: clamp(12px, 2.8vw, 16px);
		width: clamp(150px, 40vw, 220px);
		height: clamp(35px, 9vw, 50px);
	}
}
