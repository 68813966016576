@import "../../../index.css";

.container {
	display: flex;
	padding-top: 10px;
	position: relative;
}

.iconContainer {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.logoContainer {
	flex: 4;
	display: flex;
	justify-content: center;
	align-items: center;
}

.logo {
	width: auto;
	height: min(5vw, 76px);
	min-height: 60px;
}

.menuToggle {
	cursor: pointer;
}

.menuIcon {
	width: auto;
	height: min(4vw, 50px);
	min-height: 25px;
}

.navigationContainer {
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: rgba(8, 26, 22, 1);
	z-index: 3;
}

.closeContainer {
	padding: 15px 15px;
	padding-bottom: 0;
	display: flex;
	justify-content: flex-end;
	flex: 1;
}

.close {
	height: clamp(30px, 4vw, 40px);
	cursor: pointer;
}

.buttonsContainer {
	flex: 10;
	padding: 0 25px;
	display: flex;
	flex-direction: column;
}

.navButton {
	padding: 12px 0;
	color: rgba(233, 240, 235, 1);
	font-size: max(1.8vw, 18px);
	cursor: pointer;
	font-family: "Garet";
}

.separator {
	border-bottom: 1px solid rgba(233, 240, 235, 1);
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 12px;
}

.langButtonsContainer {
	flex: 15;
	padding: 0 25px;
	display: flex;
	flex-direction: column;
}

.buttons {
	padding: 5px 30px;
	color: rgba(233, 240, 235, 1);
	font-size: max(1.6vw, 16px);
	cursor: pointer;
	font-family: "Garet";
}

.buttons:hover {
	text-decoration: underline;
}

.selectedContainer {
	padding: 5px 0;
	color: rgba(233, 240, 235, 1);
	font-size: max(1.8vw, 18px);
	font-family: "Garet";
	font-weight: 600;
	background-color: rgba(107, 121, 106, 0.3);
	width: 60%;
}

@media screen and (max-width: 500px) {
	.selectedContainer {
		width: 100%;
	}
}
