@import "../../../index.css";

.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	background-color: rgba(16, 49, 42, 1);
	width: 100%;
}

.logo {
	width: auto;
	height: 6em;
	margin-top: 2.5%;
}

.socialMediaContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 5.5em;
	padding: 20px 0;
}

.facebook {
	width: 2em;
	height: auto;
}

.instagram {
	width: 2.1em;
	height: auto;
}

.buttons {
	color: rgba(233, 240, 235, 1);
	font-weight: 300;
	font-size: 0.8em;
	cursor: pointer;
	font-family: "Garet";
}

.buttonsContainer {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	width: 60%;
	max-width: 650px;
	padding: 3vh 0 4vh 0;
}

.breakLine {
	border-bottom: 1px solid rgba(233, 240, 235, 1);
	width: 20%;
	min-width: 350px;
	margin-left: auto;
	margin-right: auto;
}

@media screen and (max-width: 900px) {
	.buttonsContainer {
		width: 75%;
	}

	.logo {
		height: 5em;
	}
}

@media screen and (max-width: 500px) {
	.buttonsContainer {
		text-align: center;
		flex-wrap: wrap;
		width: 100%;
		padding-bottom: 3vh;
		max-width: 380px;
	}
	.buttons {
		margin: 5px 15px;
		flex: 1;
		min-width: 80px;
	}
}
