@import "../../index.css";

.container {
	display: "flex";
	flex-direction: "column";
	width: "100%";
}

.tabsRibbon {
	width: 100%;
	background-color: rgba(107, 121, 106, 1);
	display: flex;
	justify-content: center;
}

.contentContainer {
	display: flex;
	justify-content: space-between;
	width: 50%;
}

.tabContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.5em 1.2em;
	cursor: pointer;
	width: 9vw;
	min-width: 95px;
}

.tabContainerSelected {
	background-color: rgba(233, 240, 235, 1);
}

.icon {
	width: min(3.4vw, 50px);
}

.tabText {
	color: rgba(233, 240, 235, 1);
	font-size: clamp(10px, 1.2vw, 18px);
	font-family: GaretBold;
	font-weight: 200;
	padding-top: 0.5em;
	text-align: center;
}

.tabTextSelected {
	color: rgba(107, 121, 106, 1);
}

@media screen and (max-width: 900px) {
	.contentContainer {
		width: 80%;
		justify-content: space-evenly;
	}

	.tabText {
		font-size: clamp(10px, 1.5vw, 16px);
	}

	.tabContainer {
		padding: 1em 1em;
	}

	.icon {
		width: clamp(30px, 5vw, 40px);
	}
}

@media screen and (max-width: 500px) {
	.contentContainer {
		justify-content: space-evenly;
	}

	.tabContainer {
		padding: 1em 0.4em;
		min-width: 85px;
	}

	.icon {
		width: clamp(25px, 7vw, 40px);
	}
}
