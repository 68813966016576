@import "../../../index.css";

.container {
	display: flex;
	color: rgba(233, 240, 235, 1);
	font-weight: 300;
	font-size: min(1.3vw, 18px);
	cursor: pointer;
	font-family: "Garet";
	justify-content: center;
	align-self: center;
	border-bottom: 2px solid rgba(233, 240, 235, 1);
}

.arrow {
	width: auto;
	height: 15px;
	align-self: center;
	padding-left: 5px;
}

.dropdownContainer {
	position: absolute;
	/* height: fit-content; */
	display: flex;
	flex-direction: column;
	top: 7vh;
	padding-right: 10px;
	color: rgba(233, 240, 235, 1);
	font-weight: 300;
	font-size: min(1.3vw, 18px);
	font-family: "Garet";
}

.buttons:hover {
	text-decoration: underline;
}

.buttons {
	cursor: pointer;
}

@media screen and (max-width: 1100px) {
	.dropdownContainer {
		top: 6vh;
	}
}
