@import "../../../index.css";

.container {
	font-size: clamp(12px, 1.3vw, 20px);
	font-family: "Garet";
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.firstSection {
	display: flex;
	padding: 4em 0;
	max-width: 1400px;
}

.imageContainer {
	flex: 0.5;
	display: flex;
	justify-content: center;
	align-items: center;
}

.firstImage {
	width: min(34vw, 500px);
	height: min(34vw, 500px);
	min-height: 320px;
	z-index: 2;
}

.textContainer {
	flex: 0.5;
	padding: 4em 4em 4em 0;
	max-height: 560px;
}

.title1 {
	color: rgba(16, 49, 42, 1);
	font-size: clamp(20px, 2.5vw, 36px);
	font-weight: 800;
	font-family: BlackMango;
}

.title2 {
	color: rgba(205, 154, 73, 1);
	font-size: clamp(20px, 2.5vw, 36px);
	font-weight: 600;
	font-family: BlackMango;
}

.description {
	font-size: clamp(12px, 1.3vw, 20px);
	padding-top: 2em;
}

.gradientBackground {
	background: linear-gradient(
		90deg,
		rgba(107, 121, 106, 1) 75%,
		rgba(255, 255, 255, 1) 75%
	);
	display: flex;
	width: 100%;
	justify-content: center;
}

.locationContainer {
	display: flex;
	padding: 4em 5em 4em 0;
	max-width: 1400px;
}

.locationContainerAlt {
	flex-direction: row-reverse;
	padding-left: 4em;
}

.locationTextContainer {
	color: rgba(233, 240, 235, 1);
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: fit-content;
	padding-left: 4em;
	padding-right: 3em;
}

.locationTextAlt {
	color: rgba(16, 49, 42, 1);
	padding-right: 1em;
}

.locationImage {
	width: min(38vw, 550px);
	height: min(38vw, 550px);
	min-height: 320px;
}

.locationTitle {
	font-family: BlackMango;
	font-size: clamp(16px, 1.5vw, 24px);
	font-weight: 600;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.titleLine {
	border-top: 1px solid rgba(233, 240, 235, 1);
	width: 60%;
	height: 1px;
}

.locationNumber {
	position: absolute;
	margin-left: -1.5em;
	margin-top: -1em;
	width: min(8vw, 110px);
	height: auto;
}

@media screen and (min-width: 2000px) {
	.gradientBackground {
		background: linear-gradient(
			90deg,
			rgba(107, 121, 106, 1) 70%,
			rgba(255, 255, 255, 1) 70%
		);
	}
}

@media screen and (max-width: 900px) {
	.container {
		font-size: clamp(10px, 1.8vw, 20px);
	}
	.firstImage {
		width: min(43vw, 350px);
		height: min(58vw, 550px);
	}

	.textContainer {
		padding: 1em 2em 1em 0;
	}

	.title1 {
		font-size: clamp(16px, 3.7vw, 36px);
	}

	.title2 {
		font-size: clamp(16px, 3.7vw, 36px);
	}

	.description {
		font-size: clamp(10px, 1.8vw, 20px);
	}
	.locationTextContainer {
		padding-left: 4em;
		padding-right: 2em;
	}
	.locationContainer {
		padding: 4em 4em 4em 0;
	}
	.locationContainerAlt {
		padding-right: 2em;
		padding-left: 2em;
	}
	.titleLine {
		width: 0;
	}
	.locationTitle {
		font-size: clamp(12px, 3vw, 28px);
		border-bottom: 1px solid rgba(233, 240, 235, 0.2);
		width: fit-content;
		padding-right: 1em;
	}
	.locationNumber {
		margin-left: -1em;
	}
	.locationImage {
		width: min(43vw, 350px);
		height: min(58vw, 550px);
		margin-top: -30%;
	}
	.secondImageContainer {
		display: flex;
		align-items: center;
	}
}

@media screen and (max-width: 500px) {
	.firstSection {
		flex-direction: column;
		text-align: center;
		padding-bottom: 6em;
	}
	.firstImage {
		width: 75vw;
		min-width: 250px;
		height: auto;
		min-height: auto;
	}
	.textContainer {
		padding: 1em 2em 1em 2em;
	}
	.description {
		font-size: clamp(11px, 2vw, 20px);
	}
	.gradientBackground {
		background: linear-gradient(
			0deg,
			rgba(107, 121, 106, 1) 75%,
			rgba(255, 255, 255, 1) 75%
		);
		margin-bottom: 5em;
	}
	.locationContainer {
		flex-direction: column-reverse;
		padding: 8em 2.5em 4em 2.5em;
	}
	.locationImage {
		width: auto;
		height: min(100vw, 550px);
	}
	.secondImageContainer {
		justify-content: center;
	}
	.locationTextContainer {
		padding: 0 6vw;
	}
	.locationNumber {
		margin: 0;
		width: min(16vw, 50px);
		margin-top: -1em;
		left: 11vw;
	}
	.locationTitle {
		text-align: center;
		width: 100%;
		display: block;
		font-size: clamp(12px, 4.5vw, 22px);
		padding-top: 2em;
	}
}
